// A partir de mes, ano e incrementoMeses devuelve mes.value y ano.value

import { ref } from 'vue';

const useFecha = ({ dia = 1, mes = 0, ano = 0, incrementoMeses = 0 }) => {

    let fecha = new Date();
    if( mes === 0 && ano === 0 ){
        fecha.setDate( fecha.getDate() + incrementoMeses );
    }else{
        fecha = new Date( ano, ( ( mes - 1 ) + incrementoMeses ), dia );
    }
    dia = ref( fecha.getDay() );
    mes = ref( fecha.getMonth() + 1 );
    ano = ref( fecha.getFullYear() );

    // Obtener posición del primer día del mes. Ej: si cae en lunes, es 0
    const conversionPosicionPrimerDiaMes = [ 6, 0, 1, 2, 3, 4, 5 ];
    let primerDiaMesDate = new Date();
    primerDiaMesDate.setFullYear( ano.value, mes.value, 1 );
    const posicionPrimerDiaMes = ref( conversionPosicionPrimerDiaMes[ primerDiaMesDate.getDay() ] );

    // Número del último días del mes
    const numUltimoDiaDelMesDate = new Date( ano.value, mes.value+1, 0 );
    let numUltimoDiaDelMes = ref( numUltimoDiaDelMesDate.getDate() );

    // Nombre del mes
    const nombreMeses = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];
    const nombreMes = ref( nombreMeses[ mes.value ] )

    return{
        dia,
        mes,
        ano,
        posicionPrimerDiaMes,
        numUltimoDiaDelMes,
        nombreMes
    }

}

export default useFecha