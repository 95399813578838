<template>
    <div class="calendarioMes">
        <div class="mesYAno">{{ mesTexto }} · {{ ano }}</div>
        <ul>
            <li>Lu</li><li>Ma</li><li>Mi</li><li>Ju</li><li>Vi</li><li>Sa</li><li>Do</li>
        </ul>
        <ul class="numDias">
            <li class="espacioPrevioPrimerDiaMes" :style="espacioPrevioPrimerDiaMes"></li>
            <li v-for="dia in numUltimoDiaDelMes" :class="clase(dia)" :key="dia" @click="queEsEsto(dia)">{{ dia }}</li>
        </ul>
        <button @click="mesAnterior()">mesAnterior</button>
        <button @click="mesPosterior()">mesPosterior</button>
    </div>
</template>

<script>
    import { ref } from "vue";
    import useFecha from "@/composables/useFecha.js";

    export default {
        name: 'CalendarioMes',
        props: {
            mes: Number,
            ano: Number
        },
        setup( props ) {

            // Propiedades
            const anchuraDia =              2;
            const anchuraSemana =           '14rem';
            let ano =                       ref( props.ano );
            let mes =                       ref( props.mes );
            const fecha =                   useFecha({ mes:mes.value, ano:ano.value });
            let mesTexto =                  ref( fecha.nombreMes.value );
            let numUltimoDiaDelMes =        ref( fecha.numUltimoDiaDelMes.value );
            const anchura =                 fecha.posicionPrimerDiaMes.value * anchuraDia;
            let espacioPrevioPrimerDiaMes = ref(`width:${ anchura }rem`);
            const clase = ( dia ) => {
                if ( dia == 3 ){
                    return "azul"
                }
            }
            
            // Métodos
            const queEsEsto = ( dia ) => {
                alert( `Hiciste clic en el día ${ dia }` );
            };
            const mesAnterior = () => {
                const fechaNueva = useFecha({ mes:mes.value, ano:ano.value, incrementoMeses:-1 });
                cambiandoValores( fechaNueva );
            };
            const mesPosterior = () => {
                const fechaNueva = useFecha({ mes:mes.value, ano:ano.value, incrementoMeses:1 });
                cambiandoValores( fechaNueva );
            };
            const cambiandoValores = ( fechaNueva ) => {
                mes.value = fechaNueva.mes.value;
                numUltimoDiaDelMes.value = fechaNueva.numUltimoDiaDelMes.value;
                mesTexto.value = fechaNueva.nombreMes.value;
                espacioPrevioPrimerDiaMes.value = `width:${ fechaNueva.posicionPrimerDiaMes.value * anchuraDia }rem`;
            };

            // propiedades y metodos que se usan en el template
            return {
                mesTexto,
                mes,
                ano,
                queEsEsto,
                numUltimoDiaDelMes,
                espacioPrevioPrimerDiaMes,
                mesAnterior,
                mesPosterior,
                anchuraSemana,
                clase
            }
        }

    }
</script>
<style lang="scss" scoped>
    $anchuraDia : 2rem;
    $anchuraSemena : v-bind(anchuraSemana);
    .calendarioMes, .mesYAno{
        width: $anchuraSemena;
    }
    .mesYAno{
        text-align: center;
    }
    .calendarioMes{
        ul{
            width: $anchuraSemena;
            margin: 0;
            padding: 0;
            li{
                list-style: none;
                width: $anchuraDia;
                display: inline-block;
                text-align: center;
            }
        }
    }
    .azul{
        background-color: rgb(0, 106, 255);
        color: white;
    }
</style>