<template>
<CalendarioMes :mes="mes" :ano="ano" />
</template>

<script>
import { ref } from "vue";
import CalendarioMes from '@/components/CalendarioMes.vue';
export default {

    name: 'App',
    components: {
        CalendarioMes
    },

    setup() {
        let fechaDate = new Date(),
            mes = ref( fechaDate.getMonth() ),
            ano = ref( fechaDate.getFullYear() );
        return{
            mes, ano
        }
    }

}
</script>

<style>
#app {
    font-family: Verdana, Geneva, sans-serif;
}
</style>